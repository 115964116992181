<script>
import SecondaryButton from "@/components/SecondaryButton.vue";
export default {
	props: {
		buttonText: {
			required: false,
			type: String,
		},
		buttonColor: {
			type: String,
			default() {
				return 'gradient-button-gray'
			}
		}
	},
	components: {
		SecondaryButton,
	},
	methods: {
		getImage(image) {
			return `${require(`@/assets/images/components/MODAL/${image}`)}`;
		},
		emitClick() {
			this.$emit('click')
		}
	},
};
</script>

<template>
	<div
		class="modal bounce absolute w-full h-full flex justify-center flex-col items-center p-3 cursor-pointer right-0 bottom-0"
	>
		<div class="w-full -mt-14">
			<img
				class="block h-32 -mb-14 mx-auto relative"
				:src="getImage('star_heart.png')"
				alt="Corazón en Mano"
			/>
			<div
				class="flex flex-col items-center text-justify px-6 pt-14 pb-6 rounded-2xl gradient-gray "
			>
				<slot></slot>
				<Secondary-button
					v-if="buttonText"
					class="blue mt-3"
					:text="buttonText"
					:angle="true"
					:buttonColor="buttonColor"
					@click.native="emitClick"
				></Secondary-button>
			</div>
			<div class="spacer h-1.5"></div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@keyframes bounce {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.bounce {
	transition: transform 0.3s;
	animation: bounce 300ms;
}

.modal {
	background-color: rgba($color: #4a6c85, $alpha: 0.5);
	z-index: 10000000;
}

.secondary-button {
	&:hover {
		animation: none;
	}
}
</style>
